import {
    WageCategoryReasons,
    HighLevelOfficer,
    TimekeepingShiftInstance,
    BasicCustomer,
} from '../generated'

import { NewShiftInstance } from 'src/types/newShiftInstance'

export function shiftInstanceTransform(
    shiftInstances: TimekeepingShiftInstance[],
    officers: HighLevelOfficer[],
    customers: BasicCustomer[]
): NewShiftInstance[] {
    const scheduleShiftInstances = shiftInstances.map((shift) => {
        const hasOT = shift.scheduled_wage_results?.some(
            (result) => result.category === 'OT' || result.category === 'DT'
        )
        const hasWeeklyOT =
            hasOT &&
            shift.scheduled_wage_results?.some(
                (result) =>
                    (result.category === 'OT' || result.category === 'DT') &&
                    result.reason !== WageCategoryReasons.DAILY_OT &&
                    result.reason !== WageCategoryReasons.DAILY_DT
            )
        const hasDailyOT =
            hasOT &&
            shift.scheduled_wage_results?.some(
                (result) =>
                    result.reason === WageCategoryReasons.DAILY_OT ||
                    result.reason === WageCategoryReasons.DAILY_DT
            )

        const officer = officers.find(
            (officer) => officer.id === shift.officer_id
        )
        return {
            ...shift,
            firm_id: '',
            billing_rate: shift.shift_instance_billing_rate,
            officer: officer
                ? {
                      id: officer.id,
                      name: officer.name,
                      // Never undefined if shift is assigned, which is the only time that matters
                      always_respect_officer_pay_rate:
                          shift.always_respect_officer_pay_rate ?? false,
                      default_pay_rate: officer.default_pay_rate!,
                  }
                : undefined,
            customer: customers.find(
                (customer) => customer.id === shift.customer_id
            ) || {
                id: shift.customer_id,
                name: shift.customer_name,
                address: '',
            },
            overtime_warning: hasOT,
            weekly_overtime_hours: hasWeeklyOT,
            daily_overtime_hours: hasDailyOT,
        }
    })
    return scheduleShiftInstances
}
