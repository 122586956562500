import { UserCredentials } from 'src/generated/models/UserCredentials'
import { FunctionStatusType } from './sharedTypes'
import useSWR from 'swr'
import { ChatService } from 'src/generated/services/ChatService'
import {
    BelfryChannelCreateResponse,
    BelfryChatChannelCreate,
    BelfryChatChannelModeratorUpdate,
    BelfryChatChannelUpdate,
} from 'src/generated'
import { BelfryChannelUpdateResponse } from 'src/generated/models/BelfryChannelUpdateResponse'

type LoadUserCredentialsType = {
    credentials: UserCredentials | undefined
} & FunctionStatusType

type LoadUserCredentialsFunc = () => LoadUserCredentialsType
export const useLoadUserChatCredentials: LoadUserCredentialsFunc = () => {
    const { data, error } = useSWR(
        `/chat/credentials`,
        ChatService.readUserChatCredentialsApiV1ChatUserCredentialsGet
    )
    return {
        credentials: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadUserUnreadMessageCountType = {
    unreadCount: number | undefined
} & FunctionStatusType

type LoadUserUnreadMessageCountFunc = () => LoadUserUnreadMessageCountType
export const useLoadUserUnreadMessageCount: LoadUserUnreadMessageCountFunc =
    () => {
        const { data, error } = useSWR(
            `/chat/unread_count`,
            ChatService.readUserUnreadMessageCountApiV1ChatUnreadCountGet
        )
        return {
            unreadCount: data,
            isLoading: !error && !data,
            isError: error,
        }
    }

type createGroupChatType = (
    channelCreate: BelfryChatChannelCreate
) => Promise<BelfryChannelCreateResponse>
export const createGroupChat: createGroupChatType = async (channelCreate) => {
    return await ChatService.createChannelApiV1ChatChannelPost(channelCreate)
}

type updateChannelMembersType = (
    channelUpdate: BelfryChatChannelUpdate
) => Promise<BelfryChannelUpdateResponse>
export const updateGroupChatMembers: updateChannelMembersType = async (
    channelUpdate
) => {
    return await ChatService.updateChannelMembersApiV1ChatChannelMembersPut(
        channelUpdate
    )
}

type updateChannelModeratorsType = (
    channelUpdate: BelfryChatChannelModeratorUpdate
) => Promise<void>
export const updateGroupChatModerators: updateChannelModeratorsType = async (
    channelUpdate
) => {
    await ChatService.updateChannelModeratorsApiV1ChatChannelModeratorsPut(
        channelUpdate
    )
}
