/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Visit } from '../models/Visit';
import type { VisitableFirm } from '../models/VisitableFirm';
import type { VisitationRequest } from '../models/VisitationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VisitationService {

    /**
     * List Visitable Firms
     * @returns VisitableFirm Successful Response
     * @throws ApiError
     */
    public static listVisitableFirmsApiV1FirmsVisitableGet(): CancelablePromise<Array<VisitableFirm>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/firms/visitable',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Begin Visit
     * @param requestBody
     * @returns Visit Successful Response
     * @throws ApiError
     */
    public static beginVisitApiV1BeginVisitPost(
        requestBody: VisitationRequest,
    ): CancelablePromise<Visit> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/begin_visit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * End Visit
     * @param visitId
     * @returns Visit Successful Response
     * @throws ApiError
     */
    public static endVisitApiV1EndVisitVisitIdPost(
        visitId: string,
    ): CancelablePromise<Visit> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/end_visit/{visit_id}',
            path: {
                'visit_id': visitId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
