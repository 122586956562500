import 'stream-chat-react/dist/css/v2/index.css'
import 'stream-chat-react/dist/css/v2/index.layout.css'
import '../shared/styles/globals.css'
import 'react-phone-input-2/lib/material.css'
import React, {
    ReactNode,
    useEffect,
    useCallback,
    useMemo,
    useState,
} from 'react'
import { SideLayout } from '../components/Layouts'
import { AuthWrapper } from '../components/AuthWrapper'
import Head from 'next/head'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import Router from 'next/router'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider, CssBaseline } from '@mui/material'
import createEmotionCache from '../utils/createEmotionCache'
import lightTheme from '../shared/styles/theme/lightTheme'
import { SWRConfigWrapper } from '../components/SWRConfig'
import { RegionContextWrapper } from '../components/RegionContext'
import { BelfryAuthWrapper } from '../components/AuthWrapper'
import 'react-toastify/dist/ReactToastify.css'
import '@mobiscroll/react/dist/css/mobiscroll.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { ToastContainer } from 'react-toastify'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

// import { ZendeskWrapper } from '../components/ZendeskWrapper'
import { FirmSettingContextWrapper } from '../components/FirmSettingContext/FirmSettingContext'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ShowMobileViewContextWrapper } from '../components/MobileContextAndHoc/MobileContextAndHoc'
import { OpenAPI } from 'src/generated'
import { useFirmVisitationState } from 'src/components/FirmSwitching/firmVisitationState'

if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DD_APP_ID!,
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        site: 'datadoghq.com',
        service: process.env.NEXT_PUBLIC_DD_SERVICE!,
        env: process.env.NEXT_PUBLIC_DD_ENV!,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: 'allow',
        trackUserInteractions: true,
        allowedTracingUrls: [
            process.env.NEXT_PUBLIC_DD_ALLOWED_TRACING_ORIGINS!,
        ],
    })
    datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN!,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        forwardConsoleLogs: ['error'],
        service: 'nextjs-main-web-app',
    })
}

const clientSideEmotionCache = createEmotionCache()

const onRedirectCallback = (appState: AppState | undefined) => {
    Router.replace(appState?.returnTo || '/')
}

const useApiHeaders = () => {
    const [headers, setHeaders] = useState<Record<string, string>>({})
    const handleHeaderSet = useCallback(
        (key: string, v: string | undefined) => {
            if (headers[key] === v) {
                return
            }

            const newVal = { ...headers }
            if (v !== undefined) {
                newVal[key] = v
            } else {
                delete newVal[key]
            }
            OpenAPI.HEADERS = async () => {
                return newVal
            }

            setHeaders(newVal)
        },
        [setHeaders, headers]
    )

    return useMemo(() => {
        return {
            setHeaderVal: handleHeaderSet,
        }
    }, [handleHeaderSet])
}

const MyApp = (props: {
    Component: any
    emotionCache?: any
    pageProps: any
}) => {
    const {
        Component,
        emotionCache = clientSideEmotionCache,
        pageProps,
    } = props
    const getLayout = Component.getLayout || ((page: ReactNode) => page)
    const { visitId } = useFirmVisitationState()
    const { setHeaderVal } = useApiHeaders()

    useEffect(() => {
        setHeaderVal('Belfry-Visit', visitId || undefined)
    }, [visitId, setHeaderVal])
    return (
        <Auth0Provider
            domain={process.env.NEXT_PUBLIC_DOMAIN ?? ''}
            clientId={process.env.NEXT_PUBLIC_CLIENT_ID ?? ''}
            audience={process.env.NEXT_PUBLIC_AUDIENCE}
            scope="read:users"
            redirectUri={
                typeof window !== 'undefined'
                    ? window.location.origin
                    : undefined
            }
            onRedirectCallback={onRedirectCallback}
        >
            <CacheProvider value={emotionCache}>
                <ThemeProvider theme={lightTheme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CssBaseline />
                        <AuthWrapper>
                            <BelfryAuthWrapper>
                                <RegionContextWrapper
                                    setHeaderVal={setHeaderVal}
                                >
                                    <FirmSettingContextWrapper>
                                        <ShowMobileViewContextWrapper>
                                            <SWRConfigWrapper>
                                                <SideLayout>
                                                    <Head>
                                                        <title>Belfry</title>
                                                        <meta
                                                            name="description"
                                                            content="Belfry"
                                                        />
                                                        <link
                                                            rel="icon"
                                                            href="/favicon.svg"
                                                            type="image/svg+xml"
                                                        />
                                                        <link
                                                            rel="icon"
                                                            href="/favicon.ico"
                                                        />
                                                        <meta
                                                            name="viewport"
                                                            content="width=device-width, initial-scale=1"
                                                        />
                                                    </Head>
                                                    <ToastContainer
                                                        position="top-right"
                                                        autoClose={5000}
                                                        hideProgressBar={true}
                                                        newestOnTop={false}
                                                        closeOnClick
                                                        rtl={false}
                                                        pauseOnFocusLoss
                                                        draggable
                                                        theme="colored"
                                                        pauseOnHover
                                                    />

                                                    {getLayout(
                                                        <Component
                                                            {...pageProps}
                                                        />
                                                    )}
                                                </SideLayout>
                                            </SWRConfigWrapper>
                                        </ShowMobileViewContextWrapper>
                                    </FirmSettingContextWrapper>
                                </RegionContextWrapper>
                            </BelfryAuthWrapper>
                        </AuthWrapper>
                    </LocalizationProvider>
                </ThemeProvider>
            </CacheProvider>
        </Auth0Provider>
    )
}

export default MyApp
