import { toast } from 'react-toastify'
import useSWR from 'swr'
import {
    CHQEarningCode,
    ContractorPayroll,
    ContractorPayrollMinimal,
    EmployeePayroll,
    EmployeePayrollMinimal,
    FullPayroll,
    Payroll,
    PayrollCreate,
    PayrollService,
} from '../generated'

import { errorReasonToString } from '../utils/errorUtils'
import { format } from 'date-fns'
import { FunctionStatusType } from './sharedTypes'
import { BelfryAPIEmployeeTaxParameterResponse } from 'src/generated/models/BelfryAPIEmployeeTaxParameterResponse'
import { BelfryEmployeeOrContractorDefaultNetPaySplit } from 'src/generated/models/BelfryEmployeeOrContractorDefaultNetPaySplit'

type LoadEmployeeTaxParametersType = {
    parameters: BelfryAPIEmployeeTaxParameterResponse | undefined
} & FunctionStatusType

type LoadWorkerNetPaySplitType = {
    split: BelfryEmployeeOrContractorDefaultNetPaySplit | undefined
} & FunctionStatusType

export const useLoadFirmPayrollSetupStatus = () => {
    const { data, error } = useSWR(`/firm_payroll_setup_status/`, () =>
        PayrollService.companyStatusApiV1PayrollCompanyStatusGet()
    )
    return data
}

export const useLoadFirmPayrollList = (cursor?: string) => {
    const { data, error } = useSWR([`firm_payroll_list`, cursor], () =>
        PayrollService.getFirmPayrollsPaginatedApiV1PayrollPaginatedListGet(
            cursor
        )
    )
    return data
}

export const useLoadPayrollDetailLink = (id: string | false) => {
    const key = id ? [`/payroll_detail_link/`, id] : null
    const { data, error } = useSWR(
        key,
        () => {
            return PayrollService.getPayrollEditLinkApiV1PayrollEditLinkPost(
                id as any
            )
        },
        { refreshInterval: 0, revalidateOnFocus: false }
    )
    if (error) {
        toast.error(errorReasonToString(error))
    }
    return data
}

type LoadEmployeeTaxParametersFunc = (
    id: string
) => LoadEmployeeTaxParametersType
export const useLoadEmployeeTaxParameters: LoadEmployeeTaxParametersFunc = (
    id
) => {
    const { data, error } = useSWR(`/tax_parameters/${id}`, () =>
        PayrollService.payrollEmployeeTaxParametersApiV1PayrollAdminEmployeeTaxParametersGet(
            id
        )
    )
    return {
        parameters: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadWorkerNetPaySplitFunc = (id: string) => LoadWorkerNetPaySplitType
export const useLoadWorkerNetPaySplit: LoadWorkerNetPaySplitFunc = (id) => {
    const { data, error } = useSWR(`/net_pay_split/${id}`, () =>
        PayrollService.getEmployeeOrContractorNetPaySplitsApiV1PayrollWorkerOfficerIdNetPaySplitsGet(
            id
        )
    )
    return {
        split: data,
        isLoading: !error && !data,
        isError: error,
    }
}

export const useLoadOfficerPayrollSetupStatus = (id: string) => {
    const { data, error } = useSWR([`/officer_payroll_setup_status/`, id], () =>
        PayrollService.officerStatusApiV1PayrollOfficerIdStatusGet(id)
    )
    return data
}

export const useLoadPaySchedule = (
    startDate: Date,
    endDate: Date,
    payGroupId: string | undefined
) => {
    const { data, error } = useSWR(
        [`/payschedule/`, startDate, endDate, payGroupId],
        () =>
            PayrollService.getPayscheduleApiV1PayrollPayscheduleGet(
                format(startDate, 'yyyy-MM-dd'),
                format(endDate, 'yyyy-MM-dd'),
                payGroupId
            ),
        { refreshInterval: 0, revalidateOnFocus: false }
    )
    return data
}

export const useLoadCustomEarningCodes = () => {
    const { data, error } = useSWR(`/earning_codes/`, () =>
        PayrollService.getActiveEarningCodeListApiV1PayrollEarningCodeGet()
    )
    return data
}

type createCustomEarningCodeFunc = (
    earningCode: CHQEarningCode,
    mutate: any
) => Promise<any>
export const createCustomEarningCode: createCustomEarningCodeFunc = (
    earningCode,
    mutate
) => {
    return mutate(`/earning_codes/`, async () => {
        return await PayrollService.createEarningCodeApiV1PayrollEarningCodePost(
            earningCode
        ).catch((reason) => toast.error(errorReasonToString(reason)))
    })
}

type downloadPaperChecksPDFFunc = (payrollId: string) => void

export const downloadPaperChecksPDF: downloadPaperChecksPDFFunc = async (
    payrollId
) => {
    await PayrollService.downloadPayrollChecksApiV1PayrollPayrollIdChecksGet(
        payrollId
    )
        .then((res) => {
            const blob = new Blob([res], { type: 'application/pdf' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `paper-checks-${payrollId}.pdf`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download PDF')
            }
        })
}

export const downloadCashReqReport: downloadPaperChecksPDFFunc = async (
    payrollId
) => {
    await PayrollService.companyPayrollCashReqReportCsvApiV1PayrollCompanyCashReqReportCsvGet(
        payrollId
    )
        .then((res) => {
            const blob = new Blob([res], { type: 'application/csv' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `cash-req-${payrollId}.csv`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download CSV')
            }
        })
}

type downloadW2PreviewFunc = () => void
export const downloadW2Preview: downloadW2PreviewFunc = async () => {
    await PayrollService.payrollCompanyW2PreviewApiV1PayrollCompanyW2PreviewGet()
        .then((res) => {
            const blob = new Blob([res], { type: 'application/pdf' })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `w2-preview.csv`
            alink.click()
        })
        .catch((e) => {
            const text = errorReasonToString(e)
            if (text) {
                toast.error(text)
            } else {
                toast.error('Failed to Download CSV')
            }
        })
}

type PageInfo = {
    page: number
    pageSize: number
    total: number
}
type LoadPayrollsType = {
    payrolls: Payroll[]
    pageInfo: PageInfo | undefined
} & FunctionStatusType

type LoadPayrollsFunc = (pageNum: number, pageSize: number) => LoadPayrollsType
export const useLoadPayrolls: LoadPayrollsFunc = (pageNum, pageSize) => {
    // backend page counts starts at 1, frontend at 0. So we adjust in this func
    const adjustedPageCount = pageNum + 1
    const { data, error } = useSWR([`/payrolls`, pageNum, pageSize], () =>
        PayrollService.listAllPayrollsApiV2PayrollsGet(
            adjustedPageCount,
            pageSize
        )
    )

    return {
        payrolls: data ? data.items : [],
        pageInfo: data && {
            page: (data.page ?? 1) - 1,
            pageSize: data.size ?? 0,
            total: data.total ?? 0,
        },
        isLoading: !error && !data,
        isError: error,
    }
}

type deletePayrollFunc = (payrollId: string, mutate: any) => Promise<Payroll[]>
export const deletePayroll: deletePayrollFunc = (payrollId, mutate) => {
    return mutate(`/payrolls`, async (existing: Payroll[]) => {
        await PayrollService.removePayrollApiV2PayrollsPayrollIdDelete(
            payrollId
        )

        return existing ? existing.filter((p) => p.id != payrollId) : []
    })
}

type createOffCyclePayrollV2Func = (
    payrollCreate: PayrollCreate,
    mutate: any
) => Promise<Payroll[]>
export const createBlankOffCyclePayrollV2: createOffCyclePayrollV2Func = (
    payrollCreate,
    mutate
) => {
    return mutate(`/payrolls`, async (existing: Payroll[]) => {
        const result =
            await PayrollService.createBlankOffCyclePayrollApiV2PayrollsOffCyclePost(
                payrollCreate
            )

        return [result, ...(existing || [])]
    })
}

type LoadIndividualPayrollType = {
    payroll: Payroll | undefined
} & FunctionStatusType

type LoadIndividualPayrollFunc = (
    payrollId: string
) => LoadIndividualPayrollType
export const useLoadIndividualPayroll: LoadIndividualPayrollFunc = (
    payrollId
) => {
    const { data, error } = useSWR([`/payrolls`, payrollId], () =>
        PayrollService.getPayrollApiV2PayrollsPayrollIdGet(payrollId)
    )

    return {
        payroll: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadEmployeePayrollsType = {
    payrolls: EmployeePayrollMinimal[]
} & FunctionStatusType

type LoadEmployeePayrollsFunc = (payrollId: string) => LoadEmployeePayrollsType
export const useLoadEmployeePayrolls: LoadEmployeePayrollsFunc = (
    payrollId
) => {
    const { data, error } = useSWR([`/payrolls/employees`, payrollId], () =>
        PayrollService.getPayrollEmployeesApiV2PayrollsPayrollIdEmployeesGet(
            payrollId
        )
    )

    return {
        payrolls: data || [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadContractorPayrollsType = {
    payrolls: ContractorPayrollMinimal[]
} & FunctionStatusType

type LoadContractorPayrollsFunc = (
    payrollId: string
) => LoadContractorPayrollsType
export const useLoadContractorPayrolls: LoadContractorPayrollsFunc = (
    payrollId
) => {
    const { data, error } = useSWR([`/payrolls/contractors`, payrollId], () =>
        PayrollService.getPayrollContractorsApiV2PayrollsPayrollIdContractorsGet(
            payrollId
        )
    )

    return {
        payrolls: data || [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadSingleEmployeePayrollType = {
    payroll: EmployeePayroll | undefined
} & FunctionStatusType

type LoadSingleEmployeePayrollFunc = (
    employeePayrollId: string
) => LoadSingleEmployeePayrollType
export const useLoadSingleEmployeePayroll: LoadSingleEmployeePayrollFunc = (
    employeePayrollId
) => {
    const { data, error } = useSWR(
        [`/payrolls/employees/single`, employeePayrollId],
        () =>
            PayrollService.getEmployeePayrollByIdApiV2PayrollsEmployeesEmployeePayrollIdGet(
                employeePayrollId
            )
    )

    return {
        payroll: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadSingleContractorPayrollType = {
    payroll: ContractorPayroll | undefined
} & FunctionStatusType

type LoadSingleContractorPayrollFunc = (
    contractorPayrollId: string
) => LoadSingleContractorPayrollType
export const useLoadSingleContractorPayroll: LoadSingleContractorPayrollFunc = (
    contractorPayrollId
) => {
    const { data, error } = useSWR(
        [`/payrolls/contractors/single`, contractorPayrollId],
        () =>
            PayrollService.getContractorPayrollByIdApiV2PayrollsContractorsContractorPayrollIdGet(
                contractorPayrollId
            )
    )

    return {
        payroll: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadCalculatePayrollV2Type = {
    payroll: FullPayroll | undefined
} & FunctionStatusType
type CalculatePayrollV2Func = (payrollId: string) => LoadCalculatePayrollV2Type
export const useCalculatePayrollV2: CalculatePayrollV2Func = (payrollId) => {
    const { data, error } = useSWR([`/payrolls/full`, payrollId], () =>
        PayrollService.calculatePayrollDetailsApiV2PayrollsPayrollIdFullPost(
            payrollId
        )
    )

    return {
        payroll: data,
        isLoading: !error && !data,
        isError: error,
    }
}
