import { useLoadSchedule } from 'src/services/schedule_v2'

import { useLoadOfficers } from 'src/services/officer'
import { ScheduleShiftInstance } from 'src/generated'

import { shiftInstanceTransform } from 'src/transformers/shiftInstanceTransformer'

import { useRouter } from 'next/router'
import {
    useLoadSchedule as useLoadScheduleOld,
    useLoadCustomersForSchedule,
} from 'src/services/schedule'

import { NewShiftInstance } from 'src/types/newShiftInstance'

export function useV4ScheduleFlag() {
    const router = useRouter()
    const v4ScheduleFlag = !!router.query.v4ScheduleFlag
    return v4ScheduleFlag
}

type ScheduleResults = {
    shiftInstances: NewShiftInstance[]
    isScheduleLoading: boolean
    isScheduleError: boolean
}
export function useSchedule(
    startDate: string,
    endDate: string
): ScheduleResults {
    const v4ScheduleFlag = useV4ScheduleFlag()
    const {
        schedule,
        isScheduleLoading: isLoadingOld,
        isScheduleError: isErrorOld,
    } = useLoadScheduleOld(startDate, endDate, !!v4ScheduleFlag)
    const { shiftInstances, isScheduleLoading, isScheduleError } =
        useScheduleNew(startDate, endDate, !!v4ScheduleFlag)

    if (!!v4ScheduleFlag) {
        return {
            shiftInstances,
            isScheduleLoading,
            isScheduleError,
        }
    }

    return {
        shiftInstances: schedule.shift_schedule,
        isScheduleLoading: isLoadingOld,
        isScheduleError: isErrorOld,
    }
}

type ScheduleResultsNew = {
    shiftInstances: ScheduleShiftInstance[]
    isScheduleLoading: boolean
    isScheduleError: boolean
}

function useScheduleNew(
    startDate: string,
    endDate: string,
    v4ScheduleFlag: boolean
): ScheduleResultsNew {
    const {
        shiftInstances,
        isLoading: isScheduleLoading,
        isError: isScheduleError,
    } = useLoadSchedule(startDate, endDate, v4ScheduleFlag)

    const {
        officers,
        isLoading: isOfficersLoading,
        isError: isOfficersError,
    } = useLoadOfficers(/*onlyTerminated=*/ false, /*allOfficers=*/ true)

    const {
        customers,
        isLoading: isCustomerLoading,
        isError: isCustomerError,
    } = useLoadCustomersForSchedule(startDate, endDate)

    if (isScheduleLoading || isOfficersLoading || isCustomerLoading) {
        return {
            shiftInstances: [],
            isScheduleLoading: true,
            isScheduleError: false,
        }
    }

    if (isScheduleError || isOfficersError || isCustomerError) {
        return {
            shiftInstances: [],
            isScheduleLoading: false,
            isScheduleError: true,
        }
    }

    return {
        shiftInstances: shiftInstanceTransform(
            shiftInstances,
            officers,
            customers
        ),
        isScheduleLoading,
        isScheduleError,
    }
}
