import { Alert, Button, Stack } from '@mui/material'
import { type FC } from 'react'
import { useFirmVisitationState } from './firmVisitationState'
type FirmVisitationBannerProps = {}

export const FirmVisitationBanner: FC<FirmVisitationBannerProps> = ({}) => {
    const { visitId, setVisitId } = useFirmVisitationState()

    if (!visitId) {
        return <></>
    }
    return (
        <Stack direction="row" spacing={0.5}>
            <Alert severity="warning">Firm visitation active</Alert>
            <Button onClick={() => setVisitId(null)}>
                Return to home firm
            </Button>
        </Stack>
    )
}
