import { useEffect, useState } from 'react'
import { getSignedUrl } from 'src/services/signing'

const UPLOADCARE_BELFRYSOFTWARE = 'https://ucare.belfrysoftware.com/'
const UPLOADCARE_CDN = 'ucarecdn.com'

const isUploadcareMedia = (mediaUrl: string): boolean => {
    return (
        mediaUrl.includes(UPLOADCARE_BELFRYSOFTWARE) ||
        mediaUrl.includes(UPLOADCARE_CDN)
    )
}
const getUploadCareBaseUrl = (mediaUrl: string): string | undefined => {
    let regex
    if (mediaUrl.includes(UPLOADCARE_BELFRYSOFTWARE)) {
        if (mediaUrl.includes('autotrotate')) {
            regex =
                /(https:\/\/ucare\.belfrysoftware\.com\/[a-z0-9-]+\/-\/autorotate\/yes\/)/
        } else {
            regex = /(https:\/\/ucare\.belfrysoftware\.com\/[a-z0-9-]+\/)/
        }
    } else if (mediaUrl.includes(UPLOADCARE_CDN)) {
        regex = /(https:\/\/ucarecdn\.com\/[a-z0-9-]+\/-\/autorotate\/yes\/)/
    }

    if (!regex) {
        return undefined
    } else {
        const match = mediaUrl.match(regex)
        if (match) {
            return match[1]
        } else {
            return undefined
        }
    }
}
/*
    Returns an enhanced media url if the source is from uploadcare
    It is enhanced because we typically add "operations" as part of uploadcare resource url to minify/reduce quality
    This strips those operations from the base resource url so that we get the actual image resolution as it was uploaded.

    Since those operations only apply to uploadcare, this only works for uploadcare.
*/
export function useGetEnhancedUploadcareMedia(
    mediaUrl: string
): string | undefined {
    const [enhancedMediaUrl, setEnhancedMediaUrl] = useState<
        string | undefined
    >(undefined)
    const isMediaUploadcare = isUploadcareMedia(mediaUrl)

    useEffect(() => {
        if (isMediaUploadcare) {
            const baseUrl = getUploadCareBaseUrl(mediaUrl)
            if (baseUrl) {
                getSignedUrl(baseUrl).then((signedUrl) => {
                    setEnhancedMediaUrl(signedUrl)
                })
            }
        }
    }, [mediaUrl, isMediaUploadcare])
    return enhancedMediaUrl
}
