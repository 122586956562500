/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BelfryChannelCreateResponse } from '../models/BelfryChannelCreateResponse';
import type { BelfryChannelDelete } from '../models/BelfryChannelDelete';
import type { BelfryChannelUpdateResponse } from '../models/BelfryChannelUpdateResponse';
import type { BelfryChatChannelCreate } from '../models/BelfryChatChannelCreate';
import type { BelfryChatChannelModeratorUpdate } from '../models/BelfryChatChannelModeratorUpdate';
import type { BelfryChatChannelUpdate } from '../models/BelfryChatChannelUpdate';
import type { OfficerForEmployeeColleague } from '../models/OfficerForEmployeeColleague';
import type { UserCredentials } from '../models/UserCredentials';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatService {

    /**
     * Read User Chat Credentials
     * Return the credentials for chat user
     * @returns UserCredentials Successful Response
     * @throws ApiError
     */
    public static readUserChatCredentialsApiV1ChatUserCredentialsGet(): CancelablePromise<UserCredentials> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/chat/user/credentials',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read User Unread Message Count
     * Return the unread message count for user
     * @returns number Successful Response
     * @throws ApiError
     */
    public static readUserUnreadMessageCountApiV1ChatUnreadCountGet(): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/chat/unread_count',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Firm Colleagues For Employee
     * @returns OfficerForEmployeeColleague Successful Response
     * @throws ApiError
     */
    public static readFirmColleaguesForEmployeeApiV1ChatMembersGet(): CancelablePromise<Array<OfficerForEmployeeColleague>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/chat/members',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Channel
     * Return the credentials for chat user
     * @param requestBody
     * @returns BelfryChannelCreateResponse Successful Response
     * @throws ApiError
     */
    public static createChannelApiV1ChatChannelPost(
        requestBody: BelfryChatChannelCreate,
    ): CancelablePromise<BelfryChannelCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/chat/channel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Channel
     * Return the credentials for chat user
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteChannelApiV1ChatChannelDelete(
        requestBody: BelfryChannelDelete,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/chat/channel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Channel Members
     * This function allows either addition or removal of chat members from admins or
     * moderators
     * @param requestBody
     * @returns BelfryChannelUpdateResponse Successful Response
     * @throws ApiError
     */
    public static updateChannelMembersApiV1ChatChannelMembersPut(
        requestBody: BelfryChatChannelUpdate,
    ): CancelablePromise<BelfryChannelUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/chat/channel/members',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Channel Moderators
     * Return the credentials for chat user
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static updateChannelModeratorsApiV1ChatChannelModeratorsPut(
        requestBody: BelfryChatChannelModeratorUpdate,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/chat/channel/moderators',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
