/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Vendor } from '../models/Vendor';
import type { VendorCreate } from '../models/VendorCreate';
import type { VendorUpdate } from '../models/VendorUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VendorService {

    /**
     * Create Vendor
     * Create a new vendor.
     *
     * Args:
     * vendor_create (schemas.VendorCreate): Vendor details to create.
     * db (AsyncSession): Database session.
     * admin_user (schemas.AdminUser): Admin user.
     * Returns:
     * schemas.Vendor: The created vendor object.
     * @param requestBody
     * @returns Vendor Successful Response
     * @throws ApiError
     */
    public static createVendorApiV1VendorPost(
        requestBody: VendorCreate,
    ): CancelablePromise<Vendor> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/vendor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Vendor
     * Get vendor by ID.
     *
     * Args:
     * vendor_id (UUID): Unique identifier of the vendor.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.Vendor: The vendor object.
     *
     * Raises:
     * HTTPException: If the vendor is not found (404).
     * @param vendorId
     * @returns Vendor Successful Response
     * @throws ApiError
     */
    public static readVendorApiV1VendorVendorIdGet(
        vendorId: string,
    ): CancelablePromise<Vendor> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/vendor/{vendor_id}',
            path: {
                'vendor_id': vendorId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Vendor
     * Update vendor by ID.
     *
     * Args:
     * vendor_id (UUID): Unique identifier of the vendor.
     * vendor_update (schemas.VendorUpdate): Updated vendor details.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * schemas.Vendor: The updated vendor object.
     *
     * Raises:
     * HTTPException: If the vendor is not found (404) or if there's a ValueError (422).
     * @param vendorId
     * @param requestBody
     * @returns Vendor Successful Response
     * @throws ApiError
     */
    public static updateVendorApiV1VendorVendorIdPut(
        vendorId: string,
        requestBody: VendorUpdate,
    ): CancelablePromise<Vendor> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/vendor/{vendor_id}',
            path: {
                'vendor_id': vendorId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Vendor
     * Delete vendor by ID.
     *
     * Args:
     * vendor_id (UUID): Unique identifier of the vendor.
     * db (AsyncSession): Database session.
     *
     * Returns:
     * str: Success message.
     *
     * Raises:
     * HTTPException: If the vendor is not found (404).
     * @param vendorId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteVendorApiV1VendorVendorIdDelete(
        vendorId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/vendor/{vendor_id}',
            path: {
                'vendor_id': vendorId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Vendors
     * List all vendors.
     *
     * Args:
     * db (AsyncSession): Database session.
     *
     * Returns:
     * List[Vendor]: A list of vendor objects.
     * @returns Vendor Successful Response
     * @throws ApiError
     */
    public static readVendorsApiV1VendorsGet(): CancelablePromise<Array<Vendor>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/vendors',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
