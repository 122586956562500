import React, { useState } from 'react'
import {
    styled,
    Popover,
    Button,
    Table,
    TableCell,
    TableRow,
    TableBody,
    Skeleton,
    CircularProgress,
} from '@mui/material'
import { OfficerTerminateAndRestoreButton } from '../OfficerTerminateButton/OfficerTerminateButton'
import { Firm, Officer, FirmAddon, OfficerService } from '../../generated'
import { useRouter } from 'next/router'
import { TerminateModal } from '../Officer/TerminateModal'
import { TerminationCreateOffCyclePayModal } from '../Officer/TerminateModal/TerminationCreateOffCyclePayModal'
import { withFirmAddon, withRole } from '../withRole'
import { toast } from 'react-toastify'
import { WorkerCategoryChangeModal } from '../Officer/PaySettings/WorkerCategoryChangeModal'
import { useSWRConfig } from 'swr'
import { disableOfficerPayroll } from 'src/services/officer'
import { sendEmployeePortalInviteEmail } from 'src/services/onboarding'
import { OnboardingChangeStatusModal } from 'src/components/Officer/Onboarding/OnboardingChangeStatusModal'

const OfficerActionsButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    height: '100%',
})

const GatedTerminationCreateOffCyclePayModal = withFirmAddon(
    FirmAddon.addon_kind.CHECKHQ_PAYROLL
)(withRole(['Admin'])(TerminationCreateOffCyclePayModal))

const HasPayrollAddonTableRow = withFirmAddon(
    FirmAddon.addon_kind.CHECKHQ_PAYROLL
)(TableRow)

type OfficerActionsButtonContainerProps = {
    officer: Officer
    id: string
    firm?: Firm
    isTerminationAndPayrollPrivileged: boolean
    isAuditLogPrivileged: boolean
    isPDFExportPrivileged: boolean
}
export function OfficerActionsButton({
    officer,
    id,
    firm,
    isTerminationAndPayrollPrivileged,
    isAuditLogPrivileged,
    isPDFExportPrivileged,
}: OfficerActionsButtonContainerProps) {
    const { mutate } = useSWRConfig()
    const router = useRouter()
    const [isDownloading, setIsDownloading] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )
    const openOptions = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseOptions = () => {
        setAnchorEl(null)
    }

    const handleOpenAuditLog = () => {
        handleCloseOptions()
        router.push(`/employees/${id}/auditlog`)
    }

    const downloadPdf = async () => {
        setIsDownloading(true)
        OfficerService.createPdfForEmployeeSummaryApiV1OfficerOfficerIdPdfPost(
            id
        )
            .then((res) => {
                const blob = new Blob([res], { type: 'application/pdf' })
                const fileURL = window.URL.createObjectURL(blob)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = `${officer?.name}-employee-report.pdf`
                alink.click()
            })
            .catch(() => {
                toast.error('Failed to Download PDF')
            })
            .finally(() => setIsDownloading(false))
    }
    const onDisablePayrollClick = async () => {
        if (
            confirm(
                `Are you sure you want to disable payroll for ${officer.name}? They will not be included in Belfry's integrated payroll.`
            )
        ) {
            await disableOfficerPayroll(id, mutate)
            toast.info(`Payroll disabled for ${officer.name}`)
        }
    }

    const isTerminationModalOpen = router.query.termination_step == '1'
    const isOffCyclePayTerminationModalOpen =
        router.query.termination_step == '2'

    const isWorkerCategoryChangeModalOpen = !!router.query.change_worker_cat
    const isOnboardingModalOpen = !!router.query.enable_onboarding

    const handleModalClose = () => {
        router.push({ query: { id } })
        handleCloseOptions()
    }

    if (!firm) {
        return <Skeleton />
    }

    // If user is not privileged for any actions then don't render button
    if (
        !isAuditLogPrivileged &&
        !isPDFExportPrivileged &&
        !isTerminationAndPayrollPrivileged
    ) {
        return null
    }

    return (
        <>
            <OfficerActionsButtonContainer>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClick}
                >
                    ...
                </Button>
                <Popover
                    open={openOptions}
                    anchorEl={anchorEl}
                    onClose={handleCloseOptions}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => {
                                            router.push({
                                                query: {
                                                    id,
                                                    change_worker_cat: true,
                                                },
                                            })
                                        }}
                                        fullWidth={true}
                                        disabled={
                                            !isTerminationAndPayrollPrivileged
                                        }
                                    >
                                        Change Worker Category
                                    </Button>
                                </TableCell>
                            </TableRow>

                            {!officer.onboarding_template_id && (
                                <TableRow>
                                    <TableCell>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={() => {
                                                router.push({
                                                    query: {
                                                        id,
                                                        enable_onboarding: true,
                                                    },
                                                })
                                            }}
                                            fullWidth={true}
                                        >
                                            Enable Officer Onboarding
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}

                            <TableRow>
                                <TableCell>
                                    <OfficerTerminateAndRestoreButton
                                        officer={officer}
                                        id={id}
                                        isTerminationAndPayrollPrivileged={
                                            isTerminationAndPayrollPrivileged
                                        }
                                    />
                                </TableCell>
                            </TableRow>

                            {!officer.is_payroll_disabled ? (
                                <HasPayrollAddonTableRow>
                                    <TableCell>
                                        <Button
                                            disabled={
                                                !isTerminationAndPayrollPrivileged
                                            }
                                            onClick={onDisablePayrollClick}
                                        >
                                            Disable Payroll for Worker
                                        </Button>
                                    </TableCell>
                                </HasPayrollAddonTableRow>
                            ) : null}

                            <TableRow>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            sendEmployeePortalInviteEmail(
                                                officer
                                            )
                                        }
                                    >
                                        Send Employee Portal Email
                                    </Button>
                                </TableCell>
                            </TableRow>
                            {isAuditLogPrivileged ? (
                                <TableRow>
                                    <TableCell>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={handleOpenAuditLog}
                                            fullWidth={true}
                                        >
                                            View Audit Log
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            {isPDFExportPrivileged ? (
                                <TableRow>
                                    <TableCell>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            onClick={downloadPdf}
                                            fullWidth={true}
                                        >
                                            {isDownloading ? (
                                                <CircularProgress
                                                    color={'inherit'}
                                                    size={24}
                                                />
                                            ) : (
                                                'Download Pdf'
                                            )}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </Table>
                </Popover>
            </OfficerActionsButtonContainer>
            {isWorkerCategoryChangeModalOpen ? (
                <WorkerCategoryChangeModal
                    open={true}
                    officer={officer}
                    handleClose={handleModalClose}
                />
            ) : null}
            {isOnboardingModalOpen && (
                <OnboardingChangeStatusModal
                    open={isOnboardingModalOpen}
                    handleClose={handleModalClose}
                    officer={officer}
                />
            )}
            {isTerminationModalOpen ? (
                <TerminateModal
                    open={isTerminationModalOpen}
                    handleClose={handleModalClose}
                    officerName={officer?.name}
                    officerIsPayrollDisabled={!!officer?.is_payroll_disabled}
                    officerId={id}
                />
            ) : null}
            {!officer?.is_payroll_disabled &&
            isOffCyclePayTerminationModalOpen ? (
                <GatedTerminationCreateOffCyclePayModal
                    open={true}
                    handleClose={handleModalClose}
                    officerName={officer?.name}
                    officerId={id}
                    firm={firm}
                />
            ) : null}
        </>
    )
}
