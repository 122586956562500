/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeePayrollEarning } from './EmployeePayrollEarning';
import type { EmployeePayrollReimbursement } from './EmployeePayrollReimbursement';

export type EmployeePayroll = {
    payroll_id: string;
    id: string;
    officer_id: string;
    officer_name?: string;
    amount_cents: number;
    reimbursement_amount_cents?: number;
    payment_method?: EmployeePayroll.payment_method;
    earnings: Array<EmployeePayrollEarning>;
    reimbursements?: Array<EmployeePayrollReimbursement>;
};

export namespace EmployeePayroll {

    export enum payment_method {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

