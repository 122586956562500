import React from 'react'
import { DetailTabs } from '../DetailTabs'

import { withRole } from '../withRole'
import { useAuth0 } from '@auth0/auth0-react'

import Box from '@mui/material/Box'
import { DetailsTab } from '../../types/detailsTab'
import {
    doesFirmHavePayrollAddonHelper,
    useLoadFirmWithAddons,
} from '../../services/firm'
import { INTERNAL_USERS } from '../../constants/previewEligibleOrgs'
import { usePreviewEligible } from 'src/hooks'
import { Features } from 'src/constants/previewEligibleOrgs'
import { FullWidthFlexHeightBox } from 'src/shared/styles/theme/styled/FullWidthFlexHeightBox'

type LayoutProps = React.PropsWithChildren<{}>

const tabLinks: DetailsTab[] = [
    {
        name: 'Certifications',
        link: `/settings/certifications`,
        linkRoot: `/settings/certifications`,
    },
    {
        name: 'Scheduling',
        link: `/settings/scheduling`,
        linkRoot: `/settings/scheduling`,
    },
    {
        name: 'Timekeeping',
        link: `/settings/timekeeping`,
        linkRoot: `/settings/timekeeping`,
    },
]

const tabLinksForAdmin = [
    ...tabLinks,
    {
        name: 'Wage Rules',
        link: `/settings/wage_rules`,
        linkRoot: `/settings/wage_rules`,
    },
    {
        name: 'Regions',
        link: `/settings/regions`,
        linkRoot: `/settings/regions`,
    },
    {
        name: 'States',
        link: `/settings/states`,
        linkRoot: `/settings/states`,
    },
    {
        name: 'Payroll',
        link: `/settings/payroll`,
        linkRoot: `/settings/payroll`,
    },
    {
        name: 'Billing',
        link: `/settings/billing`,
        linkRoot: `/settings/billing`,
    },
    {
        name: 'Audit Log',
        link: `/settings/audit_log`,
        linkRoot: `/settings/audit_log`,
    },
    {
        name: 'Pay Groups',
        link: `/settings/pay_groups`,
        linkRoot: `/settings/pay_groups`,
    },
    {
        name: 'Internal Tools',
        link: `/settings/internal`,
        linkRoot: `/settings/internal`,
    },
]
const TabbedSettingsLayoutBasic = ({ children }: LayoutProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                flexShrink: 1,
                flexDirection: 'column',
                width: '100%',
                minHeight: '0',
            }}
        >
            <DetailTabs data={tabLinks} />
            {children}
        </Box>
    )
}
const TabbedSettingsLayoutAdmin = ({ children }: LayoutProps) => {
    const { firm } = useLoadFirmWithAddons()
    const isPayrollVisible = firm && doesFirmHavePayrollAddonHelper(firm)
    const { user } = useAuth0()
    const internalUser = user && user.sub && INTERNAL_USERS.includes(user.sub)
    const showWageRules = usePreviewEligible(Features.TIMEKEEPING_V2)

    const payrollGateFeatureFilter = (opt: DetailsTab) =>
        opt.link !== '/settings/payroll' || isPayrollVisible

    const internalGateFeatureFilter = (opt: DetailsTab) =>
        opt.link !== '/settings/internal' || internalUser

    const statesOfOperationGateFeatureFilter = (opt: DetailsTab) =>
        opt.link !== '/settings/states' || internalUser

    const showWageRulesLink = (opt: DetailsTab) =>
        opt.link !== '/settings/wage_rules' || showWageRules

    return (
        <FullWidthFlexHeightBox>
            <DetailTabs
                data={tabLinksForAdmin
                    .filter(payrollGateFeatureFilter)
                    .filter(internalGateFeatureFilter)
                    .filter(showWageRulesLink)
                    .filter(statesOfOperationGateFeatureFilter)}
            />
            {children}
        </FullWidthFlexHeightBox>
    )
}

export const TabbedSettingsLayout = withRole(['Admin'])(
    TabbedSettingsLayoutAdmin,
    TabbedSettingsLayoutBasic
)
