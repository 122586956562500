import useSWR from 'swr'
import { TimecardService, TimekeepingShiftInstance } from '../generated'
import { ShiftInstanceTimekeepingUpdate, ShiftDetails } from '../generated'

type LoadShiftInstancePeriodFunc = (
    startDate: string,
    endDate: string,
    v4ScheduleFlag: boolean,
    payGroupId: string | undefined,
    targetDate?: string | undefined
) => {
    shiftInstances: TimekeepingShiftInstance[]
    isLoading: boolean
    isError: boolean
}
export const useLoadShiftInstances: LoadShiftInstancePeriodFunc = (
    startDate,
    endDate,
    v4ScheduleFlag,
    payGroupId,
    targetDate
) => {
    const { data, error } = useSWR(
        v4ScheduleFlag
            ? [
                  `/timecards-new`,
                  targetDate ?? startDate,
                  targetDate ?? endDate,
                  startDate,
                  endDate,
                  payGroupId,
              ]
            : null,
        () =>
            TimecardService.getShiftInstancesForDateRangeApiV3TimecardsShiftsPeriodGet(
                startDate,
                endDate,
                payGroupId,
                targetDate
            )
    )
    return {
        shiftInstances: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

// XXX if there's no target date (pay periods) pass false as the first arg
type UpdateTimecardsFunc = (
    targetDate: string | false,
    startDate: string,
    endDate: string,
    shift_instances: ShiftInstanceTimekeepingUpdate[],
    mutate: any,
    payGroupId?: string | undefined
) => Promise<any>
export const updateShiftInstancesForTK: UpdateTimecardsFunc = (
    targetDate,
    startDate,
    endDate,
    shift_instances,
    mutate,
    payGroupId = undefined
) => {
    const firstTwo = targetDate
        ? [targetDate, targetDate]
        : [startDate, endDate]
    const key = [`/timecards-new`, ...firstTwo, startDate, endDate, payGroupId]
    return mutate(
        key,
        async (existingInstances: ShiftInstanceTimekeepingUpdate[]) => {
            const updatedShifts =
                await TimecardService.updateShiftInstancesApiV3TimecardsShiftsUpdatePut(
                    startDate,
                    endDate,
                    shift_instances,
                    targetDate ? targetDate : undefined
                )
            const updatedScheduleIds = updatedShifts.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule = existingInstances.filter(
                (shiftInstance: ShiftInstanceTimekeepingUpdate) =>
                    !updatedScheduleIds.includes(shiftInstance.id)
            )
            // invalidate the cache for the details for all edited instances
            shift_instances.map((inst: ShiftInstanceTimekeepingUpdate) => {
                return mutate(
                    ['schedule-short-details', inst.id],
                    undefined,
                    true
                )
            })
            return [...updatedShifts, ...filteredSchedule]
        }
    )
}

// Extremely similar to the above, but for the schedule, target date is just to keep signature the same
type updateShiftInstancesForScheduleTKFunc = (
    targetDate: string | false,
    startDate: string,
    endDate: string,
    shift_instances: ShiftInstanceTimekeepingUpdate[],
    mutate: any
) => Promise<any>
export const updateShiftInstancesForScheduleTK: updateShiftInstancesForScheduleTKFunc =
    (targetDate, startDate, endDate, shift_instances, mutate) => {
        const key = ['schedule', startDate, endDate]
        return mutate(key, async (schedule: TimekeepingShiftInstance[]) => {
            const updatedShifts =
                await TimecardService.updateShiftInstancesApiV3TimecardsShiftsUpdatePut(
                    startDate,
                    endDate,
                    shift_instances,
                    targetDate ? targetDate : undefined
                )
            const updatedScheduleIds = updatedShifts.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule = schedule.filter(
                (shiftInstance: TimekeepingShiftInstance) =>
                    !updatedScheduleIds.includes(shiftInstance.id)
            )
            // invalidate the cache for the details for all edited instances
            shift_instances.map((inst: ShiftInstanceTimekeepingUpdate) => {
                return mutate(
                    ['schedule-short-details', inst.id],
                    undefined,
                    true
                )
            })
            return [...updatedShifts, ...filteredSchedule]
        })
    }

type UpdateShiftDetailsFunc = (
    targetDate: string | false,
    startDate: string,
    endDate: string,
    shiftInstanceId: string,
    shiftDetailId: string,
    shiftDetails: ShiftDetails,
    mutate: any,
    payGroupId: string | undefined
) => Promise<any>
export const updateShiftDetails: UpdateShiftDetailsFunc = (
    targetDate,
    startDate,
    endDate,
    shiftInstanceId,
    shiftDetailId,
    shiftDetails,
    mutate,
    payGroupId
) => {
    const firstTwo = targetDate
        ? [targetDate, targetDate]
        : [startDate, endDate]
    const key = [`/timecards-new`, ...firstTwo, startDate, endDate, payGroupId]
    return mutate(
        key,
        async (existingInstances: ShiftInstanceTimekeepingUpdate[]) => {
            const updatedShifts =
                await TimecardService.updateShiftDetailsApiV3TimekeepingShiftInstanceIdDetailsShiftDetailIdPut(
                    shiftInstanceId,
                    shiftDetailId,
                    startDate,
                    endDate,
                    shiftDetails,
                    targetDate ? targetDate : undefined
                )
            const updatedScheduleIds = updatedShifts.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule = existingInstances.filter(
                (shiftInstance: ShiftInstanceTimekeepingUpdate) =>
                    !updatedScheduleIds.includes(shiftInstance.id)
            )
            mutate(['schedule-short-details', shiftInstanceId], undefined, true)
            return [...updatedShifts, ...filteredSchedule]
        }
    )
}
