/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContractorPayrollDeduction } from './ContractorPayrollDeduction';
import type { ContractorPayrollEarning } from './ContractorPayrollEarning';
import type { ContractorPayrollReimbursement } from './ContractorPayrollReimbursement';

export type ContractorPayroll = {
    payroll_id: string;
    id: string;
    officer_id: string;
    officer_name?: string;
    amount_cents: number;
    reimbursement_amount_cents?: number;
    deduction_amount_cents?: number;
    payment_method?: ContractorPayroll.payment_method;
    earnings: Array<ContractorPayrollEarning>;
    reimbursements?: Array<ContractorPayrollReimbursement>;
    deductions?: Array<ContractorPayrollDeduction>;
};

export namespace ContractorPayroll {

    export enum payment_method {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

