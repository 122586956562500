import useSWRImmutable from 'swr/immutable'
import useSWR from 'swr'
import { OpenAPI } from '../generated'
import { SigningService } from '../generated'

export const useLoadObject = (
    url: string | undefined,
    lowerImageQuality?: boolean
) => {
    const { data, error } = useSWRImmutable(url, async () => {
        return await getSignedUrl(url, lowerImageQuality)
    })
    return {
        signedUrl: data,
        isLoading: !error && !data,
        isError: error,
    }
}

export const getSignedUrl = async (
    url: string | undefined,
    lowerImageQuality?: boolean
) => {
    // @ts-ignore
    const token = await OpenAPI.TOKEN()
    const endpoint = `${
        process.env.NEXT_PUBLIC_API_ENDPOINT_NEW
    }/api/v1/sign?url=${encodeURIComponent(url!)}${
        lowerImageQuality ? '&quality=lightest' : ''
    }`
    const signingUrl = await fetch(endpoint, {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
        return response.url
    })
    return signingUrl
}

export const getSignedUrlSpecialAuth = (url: string, token: string) => {
    const proxyUrl = `${
        process.env.NEXT_PUBLIC_API_ENDPOINT_NEW
    }/api/v1/sign/custom?url=${encodeURIComponent(url!)}&token=${token}`
    return proxyUrl
}

export const downloadFile = (
    url: string,
    name: string,
    contentType: string
) => {
    getSignedUrl(url).then((signedUrl) =>
        fetch(signedUrl, {
            method: 'GET',
            headers: {
                'Content-Type': contentType,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', name)

                // Append to html link element page
                document.body.appendChild(link)

                // Start download
                link.click()

                // Clean up and remove the link
                if (link.parentNode) {
                    link.parentNode.removeChild(link)
                }
            })
    )
}

type ZendeskJwtType = {
    zendeskJwt: string | undefined
    isLoading: boolean
    isError: boolean
}
export const useGetZendeskJWT = (): ZendeskJwtType => {
    const options = {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 5 * 60 * 1000,
    }
    const { data, error } = useSWR(
        `zendesk_id`,
        () => SigningService.signZendeskJwtApiV1SignZendeskGet(),
        options
    )
    return {
        zendeskJwt: data,
        isLoading: !error && !data,
        isError: error,
    }
}
