import { useCallback, useEffect, useMemo, useState } from 'react'

type LocalStorageHookRet<T> = {
    value: T | null
    setValue: (arg: T) => void
}
export function useLocalStorage<T>(key: string): LocalStorageHookRet<T> {
    const [isClient, setIsClient] = useState<boolean>(false)
    useEffect(() => {
        setIsClient(true)
    }, [])

    const strVal = isClient ? window.localStorage.getItem(key) : null

    const handleSetValue = useCallback(
        (v: T) =>
            isClient && window.localStorage.setItem(key, JSON.stringify(v)),
        [isClient]
    )

    return useMemo(() => {
        let parsedVal: T | null

        try {
            parsedVal = strVal ? JSON.parse(strVal) : null
        } catch {
            parsedVal = null
        }
        return {
            value: parsedVal || null,
            setValue: handleSetValue,
        }
    }, [key, strVal, handleSetValue])
}
